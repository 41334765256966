import React, { useState, useEffect } from 'react'
import fetch from 'isomorphic-fetch'
import { WeatherUpdates } from '..'

const indexKeys = {
  date: 0,
  waveheight: 1,
  windSpeed: 6,
  tempature: 7
}

async function getData () {
  const res = await fetch('https://wrapapi.com/use/kal/vedur-og-sjolag/bakkafjara/0.0.1?wrapAPIKey=ZjfzctspawNpb9MOH8Xqxej4IRjpfrSc')
  const json = await res.json()
  const arr = json.data.output.map(obj => obj.values)

  return arr.map(blob => keyifyArray(blob, indexKeys))
}

const keyifyArray = (arr, indexKeys) => {
  return Object.entries(indexKeys).reduce((acc, [key, index]) => {
    acc[key] = arr[index]
    return acc
  }, {})
}

const WeatherUpdateData = ({ currentLang }) => {
  const [weatherUpdateData, setWeatherUpdateData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getData().then(setWeatherUpdateData).then(() => setIsLoading(false))
  }, [])

  return (
    <WeatherUpdates weatherUpdateData={weatherUpdateData} currentLang={currentLang} isLoading={isLoading} />
  )
}

export default WeatherUpdateData
